import { useGoogleSignButton } from 'hooks/auth';

import { Spinner } from 'components/shared/Spinner';

import { GoogleIcon } from './GoogleIcon';

export const GoogleSignInButton = (): JSX.Element => {
  const { onGoogleButtonPressHandler, loading } = useGoogleSignButton();

  return (
    <div className="w-full bg-white rounded-[10px] shadow hover:shadow-md active:shadow-inner">
      <button
        className="w-full flex flex-row justify-center items-center disabled:opacity-50 relative"
        disabled={loading}
        onClick={onGoogleButtonPressHandler}
      >
        <GoogleIcon />

        <span className="align-middle text-[#858585] ml-4 py-4">
          Continue with Google
        </span>

        {loading && (
          <span className="ml-3">
            <Spinner className="text-black" size={24} />
          </span>
        )}
      </button>
    </div>
  );
};

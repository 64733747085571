import { httpsCallable } from 'firebase/functions';

import { IStory } from 'types/story';

import { functions } from 'clients/firebase';

export const fetchStory = httpsCallable<{ storyId: string }, { story: IStory }>(
  functions,
  'getStory',
);
export const updateStory = httpsCallable<{ story: IStory }, { story: IStory }>(
  functions,
  'updateStory',
);

export const createStory = httpsCallable<
  { userId: string; companyId: string; departmentId?: string },
  { storyId: string }
>(functions, 'createStory');

export const deleteStory = httpsCallable<{ storyId: string }>(
  functions,
  'deleteStory',
);

export const createMuxUpload = httpsCallable<
  { storyId: string; fileName: string },
  { fireStoreId: string; muxUploadUrl: string; muxUploadId: string }
>(functions, 'createMuxUpload');

export const getCompanySlugById = httpsCallable<
  { id: string },
  { slug: string }
>(functions, 'getCompanySlugById');

export const createMultipleChoiceQuestion = httpsCallable<
  { storyId: string },
  { questionId: string }
>(functions, 'createMultipleChoiceQuestion');

export const createPoll = httpsCallable<
  { storyId: string },
  { pollId: string }
>(functions, 'createPoll');

export const createOpenQuestion = httpsCallable<
  { storyId: string },
  { questionId: string }
>(functions, 'createOpenQuestion');

import { useCallback, useState } from 'react';

export type TSection = 'sign-in' | 'sign-up';

interface ISectionsHookParams {
  initialSection: TSection;
}

export const useSections = ({ initialSection }: ISectionsHookParams) => {
  const [currentSection, setCurrentSection] =
    useState<TSection>(initialSection);

  const handleSwitchSection = useCallback(() => {
    setCurrentSection(section => {
      switch (section) {
        case 'sign-in':
          return 'sign-up';
        case 'sign-up':
          return 'sign-in';
      }
    });
  }, []);

  return {
    currentSection,
    handleSwitchSection,
  };
};

import { useState } from 'react';

import classNames from 'classnames';
import { Field, FieldProps, useField } from 'formik';

interface IProps {
  variant?: 'normal' | 'narrow';
  updateOnChange?: boolean;
}

export const TextField = ({
  name = '',
  onKeyDown,
  onChange,
  onBlur,
  variant = 'normal',
  updateOnChange = false,
  type = 'text',
  className,
  ...props
}: JSX.IntrinsicElements['input'] & IProps) => {
  const [input] = useField(name);
  const [internalValue, setInternalValue] = useState<string>(input.value);

  return (
    <Field name={name}>
      {({ field: { onChange, onBlur, name } }: FieldProps) => {
        return (
          <input
            {...props}
            name={name}
            value={internalValue}
            onChange={e => {
              setInternalValue(e.currentTarget.value);

              if (updateOnChange) {
                onChange(e);
              }
            }}
            onBlur={e => {
              onChange(e);
              onBlur(e);
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                onChange(e);
              }

              onKeyDown?.(e);
            }}
            type={type}
            className={classNames(
              'h-[54px] rounded-lg border border-black/5 bg-culturedPearlGrey p-[10px] outline-bigStoneBlue dark:bg-white dark:bg-opacity-[8%] dark:outline-carnationRed',
              className,
              { ['!h-[36px]']: variant === 'narrow' },
            )}
          />
        );
      }}
    </Field>
  );
};
